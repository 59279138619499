<template>
  <el-dialog :title="title" :visible.sync="show" width="400px" :before-close="handleClose">
    <el-form size="mini" label-width="auto">
      <el-form-item label="姓名">
        {{item.name}}
      </el-form-item>
      <el-form-item label="工号">
        {{item.number}}
      </el-form-item>
      <el-form-item label="手机号">
        {{item.phone}}
      </el-form-item>
      <el-form-item label="证件类型">
        <el-select v-model="item.idCodeType" style="width:250px" v-if="type==2" placeholder="选择证件类型" size="mini" @change="change1=true">
          <el-option v-for="item in actions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div v-else>{{actions[item.idCodeType]?actions[item.idCodeType].name:''}}</div>
      </el-form-item>
      <el-form-item label="身份证">
        <el-input v-model="item.idCode" v-if="type==2" style="width:250px" @change="change1=true" maxlength="18"></el-input>
        <span v-else>{{item.idCode}}</span>
      </el-form-item>
      <div v-if="wlynBankCard && wlynBankCard.id">
        <el-form-item label="银行卡号">
          <el-input v-model="wlynBankCard.bankCard" v-if="type==2" style="width:250px" @change="cardInput"></el-input>
          <span v-else>{{wlynBankCard.bankCard}}</span>
        </el-form-item>
        <el-form-item label="银行名称">
          <el-input v-model="wlynBankCard.bankName" readonly v-if="type==2" style="width:250px" @change="change2=true" maxlength="19"></el-input>
          <span v-else>{{wlynBankCard.bankName}}</span>
        </el-form-item>
        <el-form-item label="预留手机号">
          <el-input v-model="wlynBankCard.reservePhone" v-if="type==2" style="width:250px" @change="change2=true" maxlength="11"></el-input>
          <span v-else>{{wlynBankCard.reservePhone}}</span>
        </el-form-item>
      </div>
    </el-form>
    <div>
      <el-button @click="handleClose">关闭</el-button>
      <el-button type="primary" v-debounce="submit" v-if="type==2">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { bankCardAttribution } from '@/utils/bankCard.js'
import axios from '@/utils/http';
import { updateWlynUser, updateBankCard } from '@/api/customer'
import { CheckIdCard } from '@/utils/utils'
export default {
  name: 'InfoUpdate',
  props: ['show', 'item', 'type', 'wlynBankCard'],
  data() {
    return {
      title: '',
      change1: false,
      change2: false,
      cardType: '',
      actions: [{ name: '大陆居民身份证', id: 0 }, { name: '港澳居民身份证', id: 1 }, { name: '港澳居民内地通行证', id: 2 }]
    }
  },
  created() {
    this.title = this.type == 1 ? '客户信息' : '修改客户信息'
  },
  methods: {
    cardInput() {
      this.change2 = true
      if (this.wlynBankCard.bankCard == '') {
        this.wlynBankCard.bankName = '输入银行卡号后显示所属银行'
        this.cardType = 'error'
        return
      }
      fetch(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${this.wlynBankCard.bankCard}%20&cardBinCheck=true`).then(response => {
        return response.json();
      }).then(res => {
        console.log(111, res)
        this.cardType = res;
        if (!res.validated) {
          this.cardType = 'error'
          this.wlynBankCard.bankName = '请换储蓄卡或者输入正确卡号'
          return
        }
        if (res.cardType === "DC") {
          this.wlynBankCard.bankName = bankCardAttribution(res.bank)
        } else {
          this.wlynBankCard.bankName = '当前交易仅支持储蓄卡'
          this.cardType = 'error'
        }
      })
    },
    handleClose() {
      this.$emit('update:show', false)
    },
    submit() {
      if (this.item.idCode && this.item.idCodeType == 0 && (!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.item.idCode) || !CheckIdCard(this.item.idCode))) {
        this.$message.warning('请输入正确的身份证号')
        return
      }
      if (this.wlynBankCard.bankCard && this.cardType == 'error') {
        this.$message.warning('请输入正确的银行卡号')
        return
      }
      if (this.wlynBankCard.bankCard && (!this.wlynBankCard.reservePhone || !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.wlynBankCard.reservePhone))) {
        this.$message.warning('请输入正确的预留手机号')
        return
      }
      if (this.change1 && !this.change2) {
        updateWlynUser(this.item).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.handleClose()
            this.$parent.getList()
          } else {
            this.$message.error(res.msg || '修改失败')
          }
        })
      } else if (!this.change1 && this.change2) {
        updateBankCard(this.wlynBankCard).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.handleClose()
            this.$parent.getList()
          } else {
            this.$message.error(res.msg || '修改失败')
          }
        })
      } else if (this.change1 && this.change2) {
        axios.all([updateWlynUser(this.item), updateBankCard(this.wlynBankCard)]).then(axios.spread((acct, perms) => {
          console.log(acct, perms)
          if (acct.code == 200 && perms.code == 200) {
            this.$message.success('修改成功')
            this.handleClose()
            this.$parent.getList()
          } else {
            this.$message.error(acct.msg || '修改失败')
          }
        }))
      } else {
        this.$message.warning('没有任何修改')
      }
    }
  }
}
</script>

<style>
</style>