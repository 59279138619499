<template>
  <el-dialog title="导入客户" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="840px" :before-close="beforeClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-row>
        <el-col :span="22">
          <el-form-item label="导入模板">
            <a style="margin:0px;font-size:18px;padding:5px;" href="https://template.gongxingtech.com/客户信息导入Excel.xlsx">下载模板</a>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-upload class="upload-demo" accept=".xls,.xlsx" :action="url + '/wlynUser/importWlynUser'" :on-preview="handleTip" :on-remove="handleTip" :on-success="handleSuccess" :before-remove="handleTip" :headers="headers" :limit="1">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只允许上传Excel文件</div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="list-box">
            <el-table border stripe :data="list">
              <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
              <el-table-column prop="name" align="center" label="姓名"></el-table-column>
              <el-table-column prop="number" align="center" label="工号"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <i class="el-icon-edit" @click="delItem(scope.$index)">删除</i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" v-debounce="onSubmit">立即生成</el-button>
            <el-button @click="beforeClose">取消</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { getVouchersList } from '@/api/setting';
import { confirmImport } from '@/api/customer';
import { baseURL } from '@/utils/http';
import { getCookies } from '@/utils/utils'
export default {
  name: 'InfoExport',
  props: ['show'],
  data() {

    return {
      list: [],
      url: baseURL,
      headers: {
        'x-token': getCookies('x-token')
      },
      uploadData: {
        // sysId: getCookies('sysId'),
        // organizationId: getCookies('organizationId'),
      },
      vouchersList: [],
      form: {
        vouchersId: '',
      },
      rules: {
        vouchersId: [
          { required: true, message: '请选择折扣券', trigger: 'change' },
        ],
      },
    }
  },
  watch: {
    show(n, o) {
      if (n) {
        this.form.vouchersId = '';
      }
    }
  },
  created() {
    this.getVouchersList();
  },
  methods: {
    beforeClose() {
      this.$emit('update:show', false)
    },
    handleSuccess(e) {
      if (e.code == 200) {
        this.list = e.data;
        console.log(this.list)
        this.active = 2;
      }
      this.$message({
        message: e.msg || e.error_msg || e.message,
        type: 'success'
      });
    },
    handleTip(e) {
      this.$message({
        message: e.msg || e.error_msg,
        type: 'success'
      });
    },
    getVouchersList() {
      let that = this;
      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 100000000,
        vouchersType: 0,
        usedArea: '410000'
      };
      getVouchersList(param)
        .then((response) => {
          if (response.code == 200) {
            that.vouchersList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    delItem(index) {
      this.list.splice(index, 1)
    },
    onSubmit() {
      if (this.list.length > 0) {
        confirmImport(this.list)
          .then((response) => {
            if (response.code == 200) {
              this.beforeClose();
              this.$parent.getList()
            }
            this.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });

          })
      }
    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  width: 505px;

  height: 300px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
</style>