<template>
  <div>
    <el-form label-width="90px" inline size="mini">
      <el-form-item label="搜索：">
        <el-input style="width:200px;" placeholder="请输入名称/手机号/卡号/身份证" v-model="search.search"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select style="width:150px;" v-model="search.isComplete" placeholder="请选择">
          <el-option v-for="item in stateList" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <!-- <el-button type="primary" icon="el-icon-folder-opened" @click="exportList">导出</el-button> -->
        <el-button type="primary" icon="el-icon-folder-opened" @click="dialogExport = true">导入客户</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 230px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构" width="130" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" align="center" label="姓名"></el-table-column>
      <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
      <el-table-column prop="number" align="center" label="编号"></el-table-column>
      <el-table-column prop="idCode" align="center" label="证件类型">
        <template slot-scope="scope">
          <span>{{scope.row.idCodeType == 0 ? '大陆居民身份证' : scope.row.idCodeType == 1 ? '港澳居民身份证' : scope.row.idCodeType == 2 ? '港澳居民内地通行证' : ''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="idCode" align="center" label="证件号码"></el-table-column>
      <el-table-column prop="wlynBankCard.bankCard" align="center" label="银行卡号"></el-table-column>
      <el-table-column prop="createTime" align="center" label="录入时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-view" style="color:#333" type="text" @click="showDetail(scope.row,1)">查看</el-button>
          <el-button icon="el-icon-edit" type="text" @click="showDetail(scope.row,2)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <InfoExport :show.sync="dialogExport" v-if="dialogExport"></InfoExport>
    <InfoUpdate :show.sync="dialogUpdate" :type="type" :item="item" v-if="dialogUpdate" :wlynBankCard="wlynBankCard"></InfoUpdate>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { selectWlynUserPage } from '@/api/customer'
import { exportCommon, getCookies } from '@/utils/utils'
import InfoUpdate from './components/InfoUpdate.vue'
import InfoExport from './components/InfoExport.vue'
export default {
  name: 'customerInfo',
  components: { OrganizationSelect, InfoUpdate, InfoExport },
  data() {
    return {
      search: {
        submitOrg: '',
        pageNum: 1,
        pageSize: 10,
        search: '',
        isComplete: ''
      },
      total: 0,
      list: [],
      stateList: [
        { label: '全部', value: '' },
        { label: '信息完整', value: '0' },
        { label: '信息不完整', value: '1' },
      ],
      organizationList: [],
      dialogExport: false,
      dialogUpdate: false,
      type: '',
      item: {},
      wlynBankCard: {}
    }
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList()
  },
  methods: {

    getList() {
      let param = Object.assign({}, this.search)
      selectWlynUserPage(param).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
    showDetail(item, type) {
      this.dialogUpdate = true
      this.item = {
        id: item.id,
        name: item.name,
        phone: item.phone,
        number: item.number,
        idCode: item.idCode,
        idCodeType: item.idCodeType,
        bankCard: item.bankCard,
        bankName: item.bankName,
        reservePhone: item.reservePhone
      }
      let wlynBankCard = item.wlynBankCard || {}
      this.wlynBankCard = {
        id: wlynBankCard.id,
        bankCard: wlynBankCard.bankCard,
        bankName: wlynBankCard.bankName,
        reservePhone: wlynBankCard.reservePhone,
      }
      this.type = type
    },

    exportList() {
      let param = Object.assign({}, this.search)
      delete param.pageNum
      delete param.pageSize
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      exportCommon('/wlynUser/exportWlynUserExcel', param)
    }
  }
}
</script>